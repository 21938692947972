/* ProductSlider.css */

.product-slider-container {
  padding-top: 50px;
  width: 100%;
  margin: auto;
}

.product-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product-image {
  max-height: 300px;
  width: auto;
  min-width: 48%;
  display: block;
  margin: 0 auto; /* Center the image horizontally within its container */
}

.product-caption {
  margin-top: 20px;
}

/* Add additional styling as needed for responsiveness and aesthetics */
