.slider-item {
  position: relative;
  height: 290px; /* Set the desired height */
  overflow: hidden;
  transition: transform 0.7s ease; /* Add a smooth transition effect */
}
.slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the overlay */
  color: white;
  padding: 10px; /* Adjust the padding as needed */
  text-align: center;
  opacity: 5; /* Adjust the opacity as needed */
  font-size: 40px;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  /* Adjust the alpha value for transparency */
  filter: contrast(250%);
  border: 0px solid black; /* Adjust color and width as needed */

  text-decoration-color: aqua;
  font-weight: bolder; /* Set text bold */
}

.slider-item:hover {
  transform: scale(1.1); /* Increase scale on hover for the focus effect */
}
.slanted-line {
  position: relative;
  overflow: hidden;
  height: 290px; /* Set the height of your div */
}

.slanted-line::before,
.slanted-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 150px; /* Thickness of the line */
  background-color: aqua; /* Line color */
}

.slanted-line::before {
  bottom: 0px;
  left: 0;
  transform: skew(-45deg);
  transform-origin: bottom left;
}

.slanted-line::after {
  right: 0;
  transform: skew(45deg);
  transform-origin: bottom right;
}
/* Add any additional styles for responsiveness */
@media (max-width: 768px) {
  .slider-item {
    height: 290px; /* Adjust the height for mobile */
  }
  .overlay {
    font-size: 25px;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  }
  .slanted-line::before,
  .slanted-line::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 100px; /* Thickness of the line */
    background-color: aqua; /* Line color */
  }
}
