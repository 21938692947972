/* Global styles */
.fa-chevron-circle-down {
  padding-right: 20px;
  color: rgb(67, 83, 83);
}

.product-page {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.category {
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.product-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-list li {
  list-style: none;
  width: 100%; /* 1 column on all screens by default */
  padding: 10px;
  margin: 5px;
}

/* Tablet layout (2 divisions) */
@media (min-width: 768px) {
  .tablet-layout {
    width: 50%; /* 2 divisions on tablet */
  }
}

/* Computer layout (4 divisions) */
@media (min-width: 1024px) {
  .computer-layout {
    width: 50%; /* 4 divisions on computer */
  }
}
